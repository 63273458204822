import React from "react";
import Header from "../header";
// import NavBar from "../navBar";
import Gallery from "../gallery/gallery";
import Footer from "../footer";

const Home = () => {

    return (
        <div className="max-w-[1170px] mx-auto my-0 flex items-center flex-col">
            <Header />
            {/* <NavBar />  To be implement*/}
            <Gallery />
            <Footer/>
        </div>
    )
}

export default Home