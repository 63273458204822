import React from "react";
import Logo from '../../assets/images/logo.jpeg'

const Header = () => {

    return (
        <div className="mt-[20px] flex justify-center">
            <img className="w-[100px] sm:w-[150px]" src={Logo} alt="M4 Tech Logo" lazy />
        </div>
    )
}

export default Header