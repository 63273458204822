import React from "react";

const Footer = () => {

    return (
        <div className="flex justify-center mb-4 mt-4">
            © M4Tech All rights reserved
        </div>
    )
}

export default Footer