import React from "react";
import Data from "../../assets/data.json"


const Gallery = () => {

    return (
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-3 sm:gap-6 mt-4">
            {
                Data.map((item) => (
                    <a href={item.url} key={item.id}>
                        <div key={item.id} className="flex flex-col items-center border-4 border-red-600 w-[170px] sm:w-[270px] h-[180px] sm:h-[270px] justify-between">
                            <img className="w-[140px] sm:w-[200px]" src={item.image} alt={item.name} lazy />
                            <div className="p-2 sm:py-3 text-sm sm:text-lg font-serif truncate max-w-[170px] sm:max-w-[270px]"> {item.name}</div>
                        </div>
                    </a>

                ))
            }
        </div >
    )
}

export default Gallery