import Home from '../src/components/home'

function App() {
  return (
    <div >
      <Home />
    </div>
  );
}

export default App;

